import React from 'react'
import {Link} from 'gatsby'

export default ({solution})=> (
    <section className="section blog">
        <div className="container">
            <h1 className="title is-size-2 is-size-4-mobile">
            We bring in third party Chinese digital marketing insights and data perspective to help executives to fully understand the chinese audiences and industries dynamics with the Greater China market. 
            </h1>
            <hr className="title-underline"/>
            
            <div className="columns is-multiline">
                {
                    solution.map(({node},index)=>{
                        return(
                            <div className="column is-half" key={index}>
                                <div className="card">
                                    <div className="card-image">
                                        <figure className="image is-3by2">
                                            <Link to={`/services/${node.slug}/`}>
                                                <img src={node.featureImage.file.url} alt={`${node.title}`}/>
                                            </Link>
                                        </figure>
                                    </div>
                                    <div className="card-content">
                                        <h1 className="title is-capitalized is-size-4">{node.title}</h1>
                                        <p>
                                            <Link to={`/services/${node.slug}/`}>Learn More</Link>
                                        </p>
                                    </div>
                                </div>		
                            </div>    
                        )
                    })
                } 
            </div>
        </div>
    </section>
)