import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import Contact from '../components/contact'
import Solution from '../components/solution'
import SiteMeta from '../components/meta-data'

class SolutionPage extends React.Component {
  render() {

    const servicesNav = get(this.props,'data.allContentfulOurServices.edges')
    const data = {
      hero: 'hero-solution',
      size: 'is-medium',
      isHidden: true,
      text: 'chinese digital marketing',
      servicesNav
    }

    const metaData = get(this.props, 'data.site.siteMetadata.solutionPage')
    return (
      <Layout location={this.props.location} data={data} >
        <SiteMeta metaData={metaData}/>
        <Solution solution={servicesNav}/>
        <Contact/>
      </Layout>
    )
  }
}

export default SolutionPage

export const pageQuery = graphql`
  query SolutionPage {
    site {
      siteMetadata {
        solutionPage {
          title
          description
          imageUrl
          siteUrl
        }
      }
    } 
    allContentfulOurServices(filter: {node_locale: {eq: "en-GB"}}, sort: {order: ASC, fields: order}) {
      edges {
        node {
          title
          slug
          image{
            fluid{
              src
            }
          }
          featureImage{
            file{
              url
            }
          }
        }
      }
    }
  }
`


